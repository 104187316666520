#toast-container > .toast-info {
  background-color: #00BCD4 !important;
  background-size: 50px 50px;
}

.toast-info {
  background-color: black;
  opacity: 1 !important;
  box-shadow: none !important;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 85px !important;
  & > div {
    box-shadow: none;
  }
  .toast-title,
  .toast-message,
  a {
    color: wheat;
  }
}

#toast-container > div:hover {
  opacity: 1 !important;
}

#toast-container > div:hover {
  box-shadow: none !important;
  opacity: 1;
  -ms-filter: none !important;
  filter: none !important;
}