@import "toastr";
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";

@import "scss/variable";

@import "bootstrap/scss/bootstrap";

$fa-font-path: "~font-awesome/fonts";

@import "~font-awesome/scss/font-awesome";

.md-12 {
  font-size: 12px;
}
.md-16 {
  font-size: 16px;
}
.md-18 {
  font-size: 18px;
}
.md-20 {
  font-size: 20px;
}
.md-24 {
  font-size: 24px;
}
.md-30 {
  font-size: 30px;
}
.md-36 {
  font-size: 36px;
}
.md-48 {
  font-size: 48px;
}


@import "./css/style.css";

@import "~react-datepicker/dist/react-datepicker.css";
@import "~@uppy/core/dist/style.css";
@import "~@uppy/drag-drop/dist/style.css";
@import "./scss/notifications";

.auth-wrapper {
  position: relative;
  min-height: 100vh;
}

.logo-text {
  font-family: 'Times New Roman', Times, serif;
  color: #fff;
}

#notifications-tray {
  max-height: 400px;
  overflow-y: scroll;
}

.notification-text {
  width: 425px;
  display: block;
  white-space: pre-wrap;
}

.second-order {
  span {
    display: none;
  }
}

@media (max-width: 576px) {
  #notifications-tray {
    max-width: 280px;
  }

  .notification-row {
    width: 500px;
  }

  .notification-text {
    width: 380px;
    display: block;
    white-space: pre-wrap;
  }

  .first-order {
    order: 1;
  }
  
  .second-order {
    order: 2;
    span {
      display: contents;
    }
  }
  
  .third-order {
    order: 3;
  }
}

.notification-badge {
  position: absolute;
  top: 57%;
  left: 52%;
}

.td-whitespace-prewrap {
  td {
    white-space: pre-wrap;
  }
}

.toast-custom {
  background-color: $primary;
}
