/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
/******************* 
Global Styles 
*******************/
/* @import url(./scss/icons/font-awesome/css/font-awesome.min.css); */
@import url(../scss/icons/simple-line-icons/css/simple-line-icons.css);
@import url(../scss/icons/linea-icons/linea.css);
@import url(../scss/icons/themify-icons/themify-icons.css);
@import url(../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css);
@import url(./spinners.css);
@import url(./animate.css);
* {
  outline: none; }

.react-datepicker-wrapper {
    width: 100%;
}

body {
  background: #fff;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #67757c;
  font-weight: 300; }

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a:hover,
a:focus {
  text-decoration: none; }

a.link {
  color: #455a64; }
  a.link:hover, a.link:focus {
    color: #398bf7; }

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block; }

.img-rounded {
  border-radius: 4px; }

html body .mdi:before,
html body .mdi-set {
  line-height: initial; }

/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #455a64;
  font-family: "Montserrat", sans-serif;
  font-weight: 400; }

h1 {
  line-height: 40px;
  font-size: 36px; }

h2 {
  line-height: 36px;
  font-size: 24px; }

h3 {
  line-height: 30px;
  font-size: 21px; }

h4 {
  line-height: 22px;
  font-size: 18px; }

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400; }

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400; }

.display-5 {
  font-size: 3rem; }

.display-6 {
  font-size: 36px; }

.box {
  border-radius: 4px;
  padding: 10px; }

html body .dl {
  display: inline-block; }

html body .db {
  display: block; }

.no-wrap td,
.no-wrap th {
  white-space: nowrap; }

/*******************
Blockquote
*******************/
html body blockquote {
  border-left: 5px solid #398bf7;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px; }

.clear {
  clear: both; }

ol li {
  margin: 5px 0; }

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px; }

html body .p-10 {
  padding: 10px; }

html body .p-20 {
  padding: 20px; }

html body .p-30 {
  padding: 30px; }

html body .p-l-0 {
  padding-left: 0px; }

html body .p-l-10 {
  padding-left: 10px; }

html body .p-l-20 {
  padding-left: 20px; }

html body .p-r-0 {
  padding-right: 0px; }

html body .p-r-10 {
  padding-right: 10px; }

html body .p-r-20 {
  padding-right: 20px; }

html body .p-r-30 {
  padding-right: 30px; }

html body .p-r-40 {
  padding-right: 40px; }

html body .p-t-0 {
  padding-top: 0px; }

html body .p-t-10 {
  padding-top: 10px; }

html body .p-t-20 {
  padding-top: 20px; }

html body .p-t-30 {
  padding-top: 30px; }

html body .p-b-0 {
  padding-bottom: 0px; }

html body .p-b-5 {
  padding-bottom: 5px; }

html body .p-b-10 {
  padding-bottom: 10px; }

html body .p-b-20 {
  padding-bottom: 20px; }

html body .p-b-30 {
  padding-bottom: 30px; }

html body .p-b-40 {
  padding-bottom: 40px; }

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px; }

html body .m-l-5 {
  margin-left: 5px; }

html body .m-l-10 {
  margin-left: 10px; }

html body .m-l-15 {
  margin-left: 15px; }

html body .m-l-20 {
  margin-left: 20px; }

html body .m-l-30 {
  margin-left: 30px; }

html body .m-l-40 {
  margin-left: 40px; }

html body .m-r-5 {
  margin-right: 5px; }

html body .m-r-10 {
  margin-right: 10px; }

html body .m-r-15 {
  margin-right: 15px; }

html body .m-r-20 {
  margin-right: 20px; }

html body .m-r-30 {
  margin-right: 30px; }

html body .m-r-40 {
  margin-right: 40px; }

html body .m-t-0 {
  margin-top: 0px; }

html body .m-t-5 {
  margin-top: 5px; }

html body .m-t-10 {
  margin-top: 10px; }

html body .m-t-15 {
  margin-top: 15px; }

html body .m-t-20 {
  margin-top: 20px; }

html body .m-t-30 {
  margin-top: 30px; }

html body .m-t-40 {
  margin-top: 40px; }

html body .m-b-0 {
  margin-bottom: 0px; }

html body .m-b-5 {
  margin-bottom: 5px; }

html body .m-b-10 {
  margin-bottom: 10px; }

html body .m-b-15 {
  margin-bottom: 15px; }

html body .m-b-20 {
  margin-bottom: 20px; }

html body .m-b-30 {
  margin-bottom: 30px; }

html body .m-b-40 {
  margin-bottom: 40px; }

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top; }

html body .vm {
  vertical-align: middle; }

html body .vb {
  vertical-align: bottom; }

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5; }

.op-3 {
  opacity: 0.3; }

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700; }

html body .font-normal {
  font-weight: normal; }

html body .font-light {
  font-weight: 300; }

html body .font-medium {
  font-weight: 500; }

html body .font-16 {
  font-size: 16px; }

html body .font-14 {
  font-size: 14px; }

html body .font-10 {
  font-size: 10px; }

html body .font-18 {
  font-size: 18px; }

html body .font-20 {
  font-size: 20px; }

/*******************
Border
*******************/
html body .b-0 {
  border: none !important; }

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13); }

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13); }

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13); }

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13) !important; }

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px; }

.thumb-md {
  height: 48px;
  width: 48px; }

.thumb-lg {
  height: 88px;
  width: 88px; }

.hide {
  display: none; }

.img-circle {
  border-radius: 100%; }

.radius {
  border-radius: 4px; }

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important; }

.text-danger {
  color: #ef5350 !important; }

.text-muted {
  color: #99abb4 !important; }

.text-warning {
  color: #ffb22b !important; }

.text-success {
  color: #06d79c !important; }

.text-info {
  color: #398bf7 !important; }

.text-inverse {
  color: #2f3d4a !important; }

html body .text-blue {
  color: #02bec9; }

html body .text-purple {
  color: #7460ee; }

html body .text-primary {
  color: #745af2 !important; }

html body .text-megna {
  color: #56c0d8; }

html body .text-dark {
  color: #67757c; }

html body .text-themecolor {
  color: #398bf7; }

/*******************
Background Colors
*******************/
.bg-primary {
  background-color: #745af2 !important; }

.bg-success {
  background-color: #06d79c !important; }

.bg-info {
  background-color: #398bf7 !important; }

.bg-warning {
  background-color: #ffb22b !important; }

.bg-danger {
  background-color: #ef5350 !important; }

html body .bg-megna {
  background-color: #56c0d8; }

html body .bg-theme {
  background-color: #398bf7; }

html body .bg-inverse {
  background-color: #2f3d4a; }

html body .bg-purple {
  background-color: #7460ee; }

html body .bg-light-part {
  background-color: rgba(0, 0, 0, 0.02); }

html body .bg-light-primary {
  background-color: #f1effd; }

html body .bg-light-success {
  background-color: #e8fdeb; }

html body .bg-light-info {
  background-color: #cfecfe; }

html body .bg-light-extra {
  background-color: #ebf3f5; }

html body .bg-light-warning {
  background-color: #fff8ec; }

html body .bg-light-danger {
  background-color: #f9e7eb; }

html body .bg-light-inverse {
  background-color: #f6f6f6; }

html body .bg-light {
  background-color: #e9edf2; }

html body .bg-white {
  background-color: #ffffff; }

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #398bf7; }
  .round img {
    border-radius: 100%; }

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px; }

.round.round-info {
  background: #398bf7; }

.round.round-warning {
  background: #ffb22b; }

.round.round-danger {
  background: #ef5350; }

.round.round-success {
  background: #06d79c; }

.round.round-primary {
  background: #745af2; }

/*******************
Labels
*******************/
.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%; }

.label-rounded {
  border-radius: 60px; }

.label-custom {
  background-color: #56c0d8; }

.label-success {
  background-color: #06d79c; }

.label-info {
  background-color: #398bf7; }

.label-warning {
  background-color: #ffb22b; }

.label-danger {
  background-color: #ef5350; }

.label-megna {
  background-color: #56c0d8; }

.label-primary {
  background-color: #745af2; }

.label-purple {
  background-color: #7460ee; }

.label-red {
  background-color: #fb3a3a; }

.label-inverse {
  background-color: #2f3d4a; }

.label-default {
  background-color: #e9edf2; }

.label-white {
  background-color: #ffffff; }

.label-light-success {
  background-color: #e8fdeb;
  color: #06d79c; }

.label-light-info {
  background-color: #cfecfe;
  color: #398bf7; }

.label-light-warning {
  background-color: #fff8ec;
  color: #ffb22b; }

.label-light-danger {
  background-color: #f9e7eb;
  color: #ef5350; }

.label-light-megna {
  background-color: #e0f2f4;
  color: #56c0d8; }

.label-light-primary {
  background-color: #f1effd;
  color: #745af2; }

.label-light-inverse {
  background-color: #f6f6f6;
  color: #2f3d4a; }

/*******************
 Badge
******************/
.badge {
  font-weight: 400; }

.badge-xs {
  font-size: 9px; }

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.badge-success {
  background-color: #06d79c; }

.badge-info {
  background-color: #398bf7; }

.badge-primary {
  background-color: #745af2; }

.badge-warning {
  background-color: #ffb22b;
  color: #ffffff; }

.badge-danger {
  background-color: #ef5350; }

.badge-purple {
  background-color: #7460ee; }

.badge-red {
  background-color: #fb3a3a; }

.badge-inverse {
  background-color: #2f3d4a; }

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px; }
  ul.list-style-none li {
    list-style: none; }
    ul.list-style-none li a {
      color: #67757c;
      padding: 8px 0px;
      display: block;
      text-decoration: none; }
      ul.list-style-none li a:hover {
        color: #398bf7; }

/*******************
dropdown-item
******************/
.dropdown-item {
  padding: 8px 1rem;
  color: #67757c; }

/*******************
Layouts
******************/
.card-no-border .card {
  border: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.card-no-border .shadow-none {
  -webkit-box-shadow: none;
  box-shadow: none; }

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
  background: #ffffff; }

.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto; }

/*******************/
/*single column*/
/*******************/
.single-column .left-sidebar {
  display: none; }

.single-column .page-wrapper {
  margin-left: 0px; }

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Main sidebar
******************/
.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0px;
  z-index: 20;
  padding-top: 70px;
  background: #204772;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08); }

.fix-sidebar .left-sidebar {
  position: fixed; }

/*******************
use profile section
******************/
.sidebar-nav .user-profile > a img {
  width: 30px;
  border-radius: 100%;
  margin-right: 10px; }

.sidebar-nav .user-profile > ul {
  padding-left: 40px; }

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 70px); }
  .scroll-sidebar.ps .ps__scrollbar-y-rail {
    left: 2px;
    right: auto;
    background: none;
    width: 6px;
    /* If using `left`, there shouldn't be a `right`. */ }

.collapse.in {
  display: block; }

.sidebar-nav {
  padding: 15px 0 0 0px; }
  .sidebar-nav ul {
    margin: 0px;
    padding: 0px; }
    .sidebar-nav ul li {
      list-style: none; }
      .sidebar-nav ul li a {
        color: #fff;
        padding: 8px 35px 8px 15px;
        display: block;
        font-size: 14px;
        font-weight: 400; }
        .sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
          color: #398bf7; }
          .sidebar-nav ul li a.active i, .sidebar-nav ul li a:hover i {
            color: #398bf7; }
        .sidebar-nav ul li a.active {
          font-weight: 500;
          color: #263238; }
      .sidebar-nav ul li ul {
        padding-left: 28px; }
        .sidebar-nav ul li ul li a {
          padding: 7px 35px 7px 15px; }
        .sidebar-nav ul li ul ul {
          padding-left: 15px; }
      .sidebar-nav ul li.nav-small-cap {
        font-size: 12px;
        margin-bottom: 0px;
        padding: 14px 14px 14px 20px;
        color: #263238;
        font-weight: 500; }
      .sidebar-nav ul li.nav-devider {
        height: 1px;
        background: rgba(120, 130, 140, 0.13);
        display: block;
        margin: 15px 0; }
  .sidebar-nav > ul > li > a i {
    width: 31px;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    color: #fff; }
  .sidebar-nav > ul > li > a .label {
    float: right;
    margin-top: 6px; }
  .sidebar-nav > ul > li > a.active {
    font-weight: 400;
    background: transparent;
    color: #26c6da; }
  .sidebar-nav > ul > li {
    margin-bottom: 5px; }
    .sidebar-nav > ul > li.active > a {
      color: #fff;
      font-weight: 500;
      background: #092c48; }
      .sidebar-nav > ul > li.active > a i {
        color: #fff; }
  .sidebar-nav .waves-effect {
    transition: none;
    -webkit-transition: none;
    -o-transition: none; }

.sidebar-nav .has-arrow {
  position: relative; }
  .sidebar-nav .has-arrow::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #687384;
    right: 1em;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 47%;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out; }

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  top: 45%;
  width: 7px;
  transform: rotate(-135deg) translate(0, -50%); }

/****************
When click on sidebar toggler and also for tablet
*****************/
@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav li {
    position: relative; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    position: absolute;
    left: 70px;
    top: 46px;
    width: 220px;
    padding-bottom: 10px;
    z-index: 1001;
    background: #edf0f5;
    display: none;
    padding-left: 1px; }
  .mini-sidebar .user-profile .profile-img {
    width: 45px; }
    .mini-sidebar .user-profile .profile-img .setpos {
      top: -35px; }
  .mini-sidebar.fix-sidebar .left-sidebar {
    position: fixed; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
    height: auto !important;
    overflow: auto; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul,
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse {
    display: block; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none; }
  .mini-sidebar .left-sidebar {
    width: 70px; }
  .mini-sidebar .sidebar-nav #sidebarnav .user-profile > a {
    padding: 12px 20px; }
  .mini-sidebar .scroll-sidebar {
    padding-bottom: 0px;
    position: absolute; }
  .mini-sidebar .hide-menu,
  .mini-sidebar .nav-small-cap,
  .mini-sidebar .sidebar-footer,
  .mini-sidebar .user-profile .profile-text,
  .mini-sidebar > .label {
    display: none; }
  .mini-sidebar .nav-devider {
    width: 70px; }
  .mini-sidebar .sidebar-nav {
    background: transparent; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a {
    padding: 12px 24px;
    width: 70px; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 290px;
    background: #092c48; }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
      display: inline; }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .label {
      display: none; } }

@media (max-width: 767px) {
  .mini-sidebar .left-sidebar {
    position: fixed; }
  .mini-sidebar .left-sidebar,
  .mini-sidebar .sidebar-footer {
    left: -240px; }
  .mini-sidebar.show-sidebar .left-sidebar,
  .mini-sidebar.show-sidebar .sidebar-footer {
    left: 0px; } }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*============================================================== 
 For all pages 
 ============================================================== */
#main-wrapper {
  width: 100%;
  overflow: hidden; }

.boxed #main-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1); }
  .boxed #main-wrapper .sidebar-footer {
    position: absolute; }
  .boxed #main-wrapper .topbar {
    max-width: 1300px; }
  .boxed #main-wrapper .footer {
    display: none; }

.page-wrapper {
  background: #f4f6f9;
  padding-bottom: 60px;
  position: relative; }

.container-fluid {
  padding: 25px 25px; }

.card {
  margin-bottom: 30px; }
  .card .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #99abb4; }
  .card .card-title {
    position: relative; }

/*******************
 Topbar
*******************/
.fix-header .topbar {
  position: fixed;
  width: 100%;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05); }

.fix-header .page-wrapper {
  padding-top: 70px; }

.fix-header .right-side-panel {
  top: 70px;
  height: calc(100% - 70px); }

.topbar {
  position: relative;
  z-index: 50; }
  .topbar .navbar-collapse {
    padding: 0 10px; }
  .topbar .top-navbar {
    min-height: 70px;
    padding: 0px; }
    .topbar .top-navbar .dropdown-toggle::after {
      display: none; }
    .topbar .top-navbar .navbar-header {
      line-height: 55px;
      padding-left: 10px; }
      .topbar .top-navbar .navbar-header .navbar-brand {
        margin-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px; }
        .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
          display: none; }
        .topbar .top-navbar .navbar-header .navbar-brand b {
          line-height: 70px;
          display: inline-block; }
    .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 17px;
      line-height: 54px; }
    .topbar .top-navbar .navbar-nav > .nav-item > span {
      line-height: 65px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      padding: 0 10px; }
    .topbar .top-navbar .navbar-nav > .nav-item.show {
      background: rgba(0, 0, 0, 0.05); }
  .topbar .profile-pic {
    width: 30px;
    border-radius: 100%; }
  .topbar .dropdown-menu {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(120, 130, 140, 0.13); }
    .topbar .dropdown-menu .dropdown-item {
      padding: 7px 1.5rem; }
  .topbar ul.dropdown-user {
    padding: 0px;
    min-width: 270px; }
    .topbar ul.dropdown-user li {
      list-style: none;
      padding: 0px;
      margin: 0px; }
      .topbar ul.dropdown-user li.divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: rgba(120, 130, 140, 0.13); }
      .topbar ul.dropdown-user li .dw-user-box {
        padding: 10px 15px; }
        .topbar ul.dropdown-user li .dw-user-box .u-img {
          width: 70px;
          display: inline-block;
          vertical-align: top; }
          .topbar ul.dropdown-user li .dw-user-box .u-img img {
            width: 100%;
            border-radius: 5px; }
        .topbar ul.dropdown-user li .dw-user-box .u-text {
          display: inline-block;
          padding-left: 10px; }
          .topbar ul.dropdown-user li .dw-user-box .u-text h4 {
            margin: 0px;
            font-size: 15px; }
          .topbar ul.dropdown-user li .dw-user-box .u-text p {
            margin-bottom: 2px;
            font-size: 12px; }
          .topbar ul.dropdown-user li .dw-user-box .u-text .btn {
            color: #ffffff;
            padding: 5px 10px;
            display: inline-block; }
            .topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
              background: #e6294b; }
      .topbar ul.dropdown-user li a {
        padding: 9px 15px;
        display: block;
        color: #67757c; }
        .topbar ul.dropdown-user li a:hover {
          background: #e9edf2;
          color: #398bf7;
          text-decoration: none; }

.search-box .app-search {
  position: absolute;
  margin: 0px;
  display: block;
  z-index: 110;
  width: 100%;
  top: -1px;
  -webkit-box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  display: none;
  left: 0px; }
  .search-box .app-search input {
    width: 100.5%;
    padding: 25px 40px 25px 20px;
    border-radius: 0px;
    font-size: 17px;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in; }
    .search-box .app-search input:focus {
      border-color: #ffffff; }
  .search-box .app-search .srh-btn {
    position: absolute;
    top: 23px;
    cursor: pointer;
    background: #ffffff;
    width: 15px;
    height: 15px;
    right: 20px;
    font-size: 14px; }

.mini-sidebar .top-navbar .navbar-header {
  width: 70px;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-left: 0px; }

.logo-center .top-navbar .navbar-header {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto; }

/*******************
Notify
*******************/
.notify {
  position: relative;
  top: -28px;
  right: -7px; }
  .notify .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #ef5350;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  .notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #ef5350;
    position: absolute;
    right: 6px;
    top: -10px; }

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0; }
  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1; }
  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3; }
  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -moz-transform: scale(1);
    opacity: 0.0; } }

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0; } }

/*******************
 Horizontal-navabar
*******************/
.hdr-nav-bar .navbar {
  background: #ffffff;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding: 0 15px; }
  .hdr-nav-bar .navbar .navbar-nav > li {
    padding: 0 10px; }
    .hdr-nav-bar .navbar .navbar-nav > li > a {
      padding: 25px 5px;
      -webkit-transition: 0.2s ease-in;
      -o-transition: 0.2s ease-in;
      transition: 0.2s ease-in;
      border-bottom: 2px solid transparent; }
    .hdr-nav-bar .navbar .navbar-nav > li.active > a,
    .hdr-nav-bar .navbar .navbar-nav > li:hover > a,
    .hdr-nav-bar .navbar .navbar-nav > li > a:focus {
      border-color: #398bf7; }
  .hdr-nav-bar .navbar .navbar-nav .dropdown-toggle:after {
    display: none; }
  .hdr-nav-bar .navbar .navbar-brand {
    padding: 20px 5px; }
  .hdr-nav-bar .navbar .navbar-toggler {
    border: 0px;
    cursor: pointer; }
  .hdr-nav-bar .navbar .custom-select {
    border-radius: 60px;
    font-size: 14px;
    padding: .375rem 40px .375rem 18px; }
  .hdr-nav-bar .navbar .call-to-act {
    padding: 15px 0; }

/*******************
 Breadcrumb and page title
*******************/
.page-titles {
  background: #f4f6f9;
  margin-bottom: 20px;
  padding: 0px; }
  .page-titles h3 {
    margin-bottom: 0px;
    margin-top: 0px; }
  .page-titles .breadcrumb {
    padding: 0px;
    margin-bottom: 0px;
    background: transparent;
    font-size: 14px; }
    .page-titles .breadcrumb li {
      margin-top: 0px;
      margin-bottom: 0px; }
    .page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: #a6b7bf;
      font-size: 11px; }
    .page-titles .breadcrumb .breadcrumb-item.active {
      color: #99abb4; }

.p-relative {
  position: relative; }

.lstick {
  width: 2px;
  background: #398bf7;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  display: inline-block;
  vertical-align: middle; }

/*******************
 Right side toggle
*******************/
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.right-side-toggle {
  position: relative; }

.right-side-toggle i {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 18px;
  left: 18px; }

.right-sidebar {
  position: fixed;
  right: -240px;
  width: 240px;
  display: none;
  z-index: 1100;
  background: #ffffff;
  top: 0px;
  padding-bottom: 20px;
  height: 100%;
  -webkit-box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .right-sidebar .rpanel-title {
    display: block;
    padding: 24px 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 15px;
    background: #398bf7; }
    .right-sidebar .rpanel-title span {
      float: right;
      cursor: pointer;
      font-size: 11px; }
      .right-sidebar .rpanel-title span:hover {
        color: #ffffff; }
  .right-sidebar .r-panel-body {
    padding: 20px; }
    .right-sidebar .r-panel-body ul {
      margin: 0px;
      padding: 0px; }
      .right-sidebar .r-panel-body ul li {
        list-style: none;
        padding: 5px 0; }

.shw-rside {
  right: 0px;
  width: 240px;
  display: block; }

.chatonline img {
  margin-right: 10px;
  float: left;
  width: 30px; }

.chatonline li a {
  padding: 13px 0;
  float: left;
  width: 100%; }
  .chatonline li a span {
    color: #67757c; }
    .chatonline li a span small {
      display: block;
      font-size: 10px; }

/*******************
 Right side toggle
*******************/
ul#themecolors {
  display: block; }
  ul#themecolors li {
    display: inline-block; }
    ul#themecolors li:first-child {
      display: block; }
    ul#themecolors li a {
      width: 50px;
      height: 50px;
      display: inline-block;
      margin: 5px;
      color: transparent;
      position: relative; }
      ul#themecolors li a.working:before {
        content: "\f00c";
        font-family: "FontAwesome";
        font-size: 18px;
        line-height: 50px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        text-align: center; }

.default-theme {
  background: #99abb4; }

.green-theme {
  background: #06d79c; }

.yellow-theme {
  background: #ffb22b; }

.red-theme {
  background: #ef5350; }

.blue-theme {
  background: #398bf7; }

.purple-theme {
  background: #7460ee; }

.megna-theme {
  background: #56c0d8; }

.default-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #99abb4), color-stop(99%, #99abb4));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%); }

.green-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #06d79c 23%, #06d79c 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #00c292 23%, #06d79c 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #06d79c), color-stop(99%, #06d79c));
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #06d79c 23%, #06d79c 99%);
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #06d79c 23%, #06d79c 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #06d79c 23%, #06d79c 99%); }

.yellow-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #ef5350), color-stop(99%, #ef5350));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%); }

.blue-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #398bf7), color-stop(99%, #398bf7));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%); }

.purple-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #7460ee), color-stop(99%, #7460ee));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%); }

.megna-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #56c0d8), color-stop(99%, #56c0d8));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%); }

.red-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #ef5350), color-stop(99%, #ef5350));
  background: -o-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  background: linear-gradient(to right, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%); }

/*******************
 Footer
*******************/
.footer {
  bottom: 0;
  color: #67757c;
  left: 0px;
  padding: 17px 15px;
  position: absolute;
  right: 0;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  background: #ffffff; }

/*******************
 scrollbar
*******************/
.slimScrollBar {
  z-index: 10 !important; }

/*******************
Megamenu
******************/
.mega-dropdown {
  position: static;
  width: 100%; }
  .mega-dropdown .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px; }
  .mega-dropdown ul {
    padding: 0px; }
    .mega-dropdown ul li {
      list-style: none; }
  .mega-dropdown .carousel-item .container {
    padding: 0px; }
  .mega-dropdown .nav-accordion .card {
    margin-bottom: 1px; }
  .mega-dropdown .nav-accordion .card-header {
    background: #ffffff; }
    .mega-dropdown .nav-accordion .card-header h5 {
      margin: 0px; }
      .mega-dropdown .nav-accordion .card-header h5 a {
        text-decoration: none;
        color: #67757c; }

/*******************
Wave Effects
*******************/
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out; }
  .waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none; }

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45); }

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7); }

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7); }

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7); }

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7); }

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7); }

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7); }

html body .waves-notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none; }

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none; }

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom; }
  .waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1; }

.waves-block {
  display: block; }

/*******************
List inline
*******************/
ul.list-inline li {
  display: inline-block;
  padding: 0 8px; }

/*******************
Table td vertical middle
*******************/
.vm.table td,
.vm.table th {
  vertical-align: middle; }

.no-th-brd.table th {
  border: 0px; }

.table.no-border tbody td {
  border: 0px; }

/*******************
Other comon js for all pages
*******************/
.calendar-events {
  padding: 8px 10px;
  border: 1px solid #ffffff;
  cursor: move; }
  .calendar-events:hover {
    border: 1px dashed rgba(120, 130, 140, 0.13); }
  .calendar-events i {
    margin-right: 8px; }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.single-column .left-sidebar {
  display: none; }

.single-column .page-wrapper {
  margin-left: 0px !important; }

.profile-tab li a.nav-link,
.customtab li a.nav-link {
  border: 0px;
  padding: 15px 20px;
  color: #67757c; }
  .profile-tab li a.nav-link.active,
  .customtab li a.nav-link.active {
    border-bottom: 2px solid #398bf7;
    color: #398bf7; }
  .profile-tab li a.nav-link:hover,
  .customtab li a.nav-link:hover {
    color: #398bf7; }

html body .jqstooltip,
html body .flotTip {
  width: auto !important;
  height: auto !important;
  background: #263238;
  color: #ffffff;
  padding: 5px 10px; }

body .jqstooltip {
  border-color: transparent;
  border-radius: 60px; }

/*******************
Notification page Jquery toaster
******************/
.jq-icon-info {
  background-color: #398bf7;
  color: #ffffff; }

.jq-icon-success {
  background-color: #06d79c;
  color: #ffffff; }

.jq-icon-error {
  background-color: #ef5350;
  color: #ffffff; }

.jq-icon-warning {
  background-color: #ffb22b;
  color: #ffffff; }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Pagination
*******************/
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; }

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

.pagination > li > a,
.pagination > li > span {
  color: #263238; }

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #e9edf2; }

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left; }

.pagination-split li:first-child {
  margin-left: 0; }

.pagination-split li a {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #398bf7;
  border-color: #398bf7; }

.pager li > a,
.pager li > span {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #263238; }

/*******************
Pagination
******************/
.pagination-circle li.active a {
  background: #06d79c; }

.pagination-circle li a {
  width: 40px;
  height: 40px;
  background: #e9edf2;
  border: 0px;
  text-align: center;
  border-radius: 100%; }
  .pagination-circle li a:first-child, .pagination-circle li a:last-child {
    border-radius: 100%; }
  .pagination-circle li a:hover {
    background: #06d79c;
    color: #ffffff; }

.pagination-circle li.disabled a {
  background: #e9edf2;
  color: rgba(120, 130, 140, 0.13); }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Buttons
******************/
.btn {
  padding: 7px 12px;
  cursor: pointer; }

.btn-group label {
  color: #ffffff !important;
  margin-bottom: 0px; }
  .btn-group label.btn-secondary {
    color: #67757c !important; }

.btn-lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem; }

.btn-md {
  padding: 12px 55px;
  font-size: 16px; }

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px; }

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px; }

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 23px; }

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px; }

.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px; }

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px; }

.button-list button,
.button-list a {
  margin: 5px 12px 5px 0; }

.btn-outline {
  color: inherit;
  background-color: transparent;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s; }

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px; }
  .btn-rounded.btn-lg {
    padding: .75rem 1.5rem; }
  .btn-rounded.btn-sm {
    padding: .25rem .5rem;
    font-size: 12px; }
  .btn-rounded.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px; }
  .btn-rounded.btn-md {
    padding: 12px 35px;
    font-size: 16px; }

.btn-secondary,
.btn-secondary.disabled {
  -webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color: #ffffff;
  color: #67757c;
  border-color: #cccccc; }
  .btn-secondary:hover,
  .btn-secondary.disabled:hover {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important; }
  .btn-secondary.active, .btn-secondary:active, .btn-secondary:focus,
  .btn-secondary.disabled.active,
  .btn-secondary.disabled:active,
  .btn-secondary.disabled:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important;
    background: #263238;
    border-color: #263238; }

.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled:active {
  color: #67757c !important;
  background-color: #ffffff;
  border-color: #cccccc;
  cursor: default; }

.btn-primary,
.btn-primary.disabled {
  background: #745af2;
  border: 1px solid #745af2;
  -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-primary:hover,
  .btn-primary.disabled:hover {
    background: #745af2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    border: 1px solid #745af2; }
  .btn-primary.active, .btn-primary:active, .btn-primary:focus,
  .btn-primary.disabled.active,
  .btn-primary.disabled:active,
  .btn-primary.disabled:focus {
    background: #6352ce;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    border-color: transparent; }

.btn-themecolor,
.btn-themecolor.disabled {
  background: #398bf7;
  color: #ffffff;
  border: 1px solid #398bf7; }
  .btn-themecolor:hover,
  .btn-themecolor.disabled:hover {
    background: #398bf7;
    opacity: 0.7;
    border: 1px solid #398bf7; }
  .btn-themecolor.active, .btn-themecolor:active, .btn-themecolor:focus,
  .btn-themecolor.disabled.active,
  .btn-themecolor.disabled:active,
  .btn-themecolor.disabled:focus {
    background: #028ee1; }

.btn-success,
.btn-success.disabled {
  background: #06d79c;
  border: 1px solid #06d79c;
  -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-success:hover,
  .btn-success.disabled:hover {
    background: #06d79c;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border: 1px solid #06d79c; }
  .btn-success.active, .btn-success:active, .btn-success:focus,
  .btn-success.disabled.active,
  .btn-success.disabled:active,
  .btn-success.disabled:focus {
    background: #04b381;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border-color: transparent; }

.btn-info,
.btn-info.disabled {
  background: #398bf7;
  border: 1px solid #398bf7;
  -webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-info:hover,
  .btn-info.disabled:hover {
    background: #398bf7;
    border: 1px solid #398bf7;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2); }
  .btn-info.active, .btn-info:active, .btn-info:focus,
  .btn-info.disabled.active,
  .btn-info.disabled:active,
  .btn-info.disabled:focus {
    background: #028ee1;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    border-color: transparent; }

.btn-warning,
.btn-warning.disabled {
  background: #ffb22b;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  border: 1px solid #ffb22b;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-warning:hover,
  .btn-warning.disabled:hover {
    background: #ffb22b;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border: 1px solid #ffb22b; }
  .btn-warning.active, .btn-warning:active, .btn-warning:focus,
  .btn-warning.disabled.active,
  .btn-warning.disabled:active,
  .btn-warning.disabled:focus {
    background: #e9ab2e;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border-color: transparent; }

.btn-danger,
.btn-danger.disabled {
  background: #ef5350;
  border: 1px solid #ef5350;
  -webkit-box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-danger:hover,
  .btn-danger.disabled:hover {
    background: #ef5350;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    border: 1px solid #ef5350; }
  .btn-danger.active, .btn-danger:active, .btn-danger:focus,
  .btn-danger.disabled.active,
  .btn-danger.disabled:active,
  .btn-danger.disabled:focus {
    background: #e6294b;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    border-color: transparent; }

.btn-inverse,
.btn-inverse.disabled {
  background: #2f3d4a;
  border: 1px solid #2f3d4a;
  color: #ffffff; }
  .btn-inverse:hover,
  .btn-inverse.disabled:hover {
    background: #2f3d4a;
    opacity: 0.7;
    color: #ffffff;
    border: 1px solid #2f3d4a; }
  .btn-inverse.active, .btn-inverse:active, .btn-inverse:focus,
  .btn-inverse.disabled.active,
  .btn-inverse.disabled:active,
  .btn-inverse.disabled:focus {
    background: #232a37;
    color: #ffffff; }

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff; }
  .btn-red:hover,
  .btn-red.disabled:hover {
    opacity: 0.7;
    border: 1px solid #fb3a3a;
    background: #fb3a3a; }
  .btn-red.active, .btn-red:active, .btn-red:focus,
  .btn-red.disabled.active,
  .btn-red.disabled:active,
  .btn-red.disabled:focus {
    background: #e6294b; }

.btn-outline-secondary {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  border-color: #cccccc; }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2); }
  .btn-outline-secondary.active, .btn-outline-secondary:active, .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2); }

.btn-outline-primary {
  color: #745af2;
  background-color: #ffffff;
  border-color: #745af2;
  -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
    background: #745af2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    color: #ffffff;
    border-color: #745af2; }
  .btn-outline-primary.active, .btn-outline-primary:active, .btn-outline-primary:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    background-color: #6352ce;
    border-color: #6352ce; }

.btn-outline-success {
  color: #06d79c;
  background-color: transparent;
  border-color: #06d79c;
  -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus {
    background: #06d79c;
    border-color: #06d79c;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2); }
  .btn-outline-success.active, .btn-outline-success:active, .btn-outline-success:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    background-color: #04b381;
    border-color: #04b381; }

.btn-outline-info {
  color: #398bf7;
  background-color: transparent;
  border-color: #398bf7;
  -webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
    background: #398bf7;
    border-color: #398bf7;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2); }
  .btn-outline-info.active, .btn-outline-info:active, .btn-outline-info:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    background-color: #028ee1;
    border-color: #028ee1; }

.btn-outline-warning {
  color: #ffb22b;
  background-color: transparent;
  border-color: #ffb22b;
  -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
    background: #ffb22b;
    border-color: #ffb22b;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2); }
  .btn-outline-warning.active, .btn-outline-warning:active, .btn-outline-warning:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    background-color: #e9ab2e;
    border-color: #e9ab2e; }

.btn-outline-danger {
  color: #ef5350;
  background-color: transparent;
  border-color: #ef5350;
  -webkit-box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; }
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
    background: #ef5350;
    border-color: #ef5350;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2); }
  .btn-outline-danger.active, .btn-outline-danger:active, .btn-outline-danger:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    background-color: #e6294b; }

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a; }
  .btn-outline-red:hover, .btn-outline-red:focus, .btn-outline-red.focus {
    background: #fb3a3a;
    border-color: #fb3a3a;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2); }
  .btn-outline-red.active, .btn-outline-red:active, .btn-outline-red:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    background-color: #d61f1f; }

.btn-outline-inverse {
  color: #2f3d4a;
  background-color: transparent;
  border-color: #2f3d4a; }
  .btn-outline-inverse:hover, .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    background: #2f3d4a;
    border-color: #2f3d4a;
    color: #ffffff; }

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #6352ce;
  border: 1px solid #6352ce; }

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus,
.show > .btn-success.dropdown-toggle {
  background-color: #04b381;
  border: 1px solid #04b381; }

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus,
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border: 1px solid #028ee1; }

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus,
.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e; }

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus,
.show > .btn-danger.dropdown-toggle {
  background-color: #e6294b;
  border: 1px solid #e6294b; }

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37; }

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff; }

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff; }

.button-box .btn {
  margin: 0 8px 8px 0px; }

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px; }

.btn-facebook {
  color: #ffffff;
  background-color: #3b5998; }
  .btn-facebook:hover {
    color: #ffffff; }

.btn-twitter {
  color: #ffffff;
  background-color: #55acee; }
  .btn-twitter:hover {
    color: #ffffff; }

.btn-linkedin {
  color: #ffffff;
  background-color: #007bb6; }
  .btn-linkedin:hover {
    color: #ffffff; }

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89; }
  .btn-dribbble:hover {
    color: #ffffff; }

.btn-googleplus {
  color: #ffffff;
  background-color: #dd4b39; }
  .btn-googleplus:hover {
    color: #ffffff; }

.btn-instagram {
  color: #ffffff;
  background-color: #3f729b; }

.btn-pinterest {
  color: #ffffff;
  background-color: #cb2027; }

.btn-dropbox {
  color: #ffffff;
  background-color: #007ee5; }

.btn-flickr {
  color: #ffffff;
  background-color: #ff0084; }

.btn-tumblr {
  color: #ffffff;
  background-color: #32506d; }

.btn-skype {
  color: #ffffff;
  background-color: #00aff0; }

.btn-youtube {
  color: #ffffff;
  background-color: #bb0000; }

.btn-github {
  color: #ffffff;
  background-color: #171515; }

/*============================================================== 
 Buttons page
 ============================================================== */
.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px; }

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px; }

.btn .text-active {
  display: none; }

.btn.active .text-active {
  display: inline-block; }

.btn.active .text {
  display: none; }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Custom-select
******************/
.custom-select {
  background: url(../images/custom-select.png) right 0.75rem center no-repeat; }

/*******************
textarea
******************/
textarea {
  resize: none; }

/*******************
Form-control
******************/
.form-control {
  color: #67757c;
  min-height: 38px;
  display: initial; }

.form-control-sm {
  min-height: 20px; }

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.7; }

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none; }

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #06d79c; }

form label {
  font-weight: 400; }

.form-group {
  margin-bottom: 25px; }

.form-horizontal label {
  margin-bottom: 0px; }

.form-control-static {
  padding-top: 0px; }

.form-bordered .form-group {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding-bottom: 20px; }

/*******************
Form Dropzone
******************/
.dropzone {
  border: 1px dashed #b1b8bb; }
  .dropzone .dz-message {
    padding: 5% 0;
    margin: 0px; }

/*******************
Form Pickers
******************/
.asColorPicker-dropdown {
  max-width: 260px; }

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0; }

.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-decoration: none; }

table th {
  font-weight: 400; }

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #398bf7; }

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #398bf7;
  color: #ffffff; }

.datepicker td,
.datepicker th {
  padding: 5px 10px; }

/*******************
Form summernote
******************/
.note-popover,
.note-icon-caret {
  display: none; }

.note-editor.note-frame {
  border: 1px solid #b1b8bb; }
  .note-editor.note-frame .panel-heading {
    padding: 6px 10px 10px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

.label {
  display: inline-block; }

/*============================================================== 
Form addons page
============================================================== */
/*Bootstrap select*/
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select .dropdown-menu li a {
  display: block;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #67757c;
  white-space: nowrap; }
  .bootstrap-select .dropdown-menu li a:hover, .bootstrap-select .dropdown-menu li a:focus {
    color: #398bf7;
    background: #e9edf2; }

.bootstrap-select .show > .dropdown-menu {
  display: block; }

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding: 9px 10px; }

.select2-container--default .select2-selection--single {
  border-color: #b1b8bb;
  height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 33px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  float: right;
  color: #ffffff;
  margin-right: 0px;
  margin-left: 4px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #398bf7;
  color: #ffffff;
  border-color: #398bf7; }

/*============================================================== 
Form Material page
 ============================================================== */
/*Material inputs*/
.form-material .form-group {
  overflow: hidden; }

.form-material .form-control {
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  -webkit-transition: background 0s ease-out 0s;
  -o-transition: background 0s ease-out 0s;
  transition: background 0s ease-out 0s; }

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#e9edf2), to(#e9edf2));
  background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#e9edf2, #e9edf2);
  background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#e9edf2, #e9edf2);
  background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#e9edf2, #e9edf2);
  border: 0 none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none; }

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s; }

.form-control-line .form-group {
  overflow: hidden; }

.form-control-line .form-control {
  border: 0px;
  border-radius: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #f6f9ff; }
  .form-control-line .form-control:focus {
    border-bottom: 1px solid #398bf7; }

/*******************
Form validation error 
******************/
.error .help-block {
  color: #fb3a3a; }

/*******************
File Upload 
******************/
.fileupload {
  overflow: hidden;
  position: relative; }
  .fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

/*******************
This is for the checkbox radio button and switch 
******************/
/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  -o-transition: .28s ease;
  transition: .28s ease;
  /* webkit (konqueror) browsers */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  -o-transition: .28s ease;
  transition: .28s ease; }

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid #b1b8bb; }

[type="radio"]:not(:checked) + label:after {
  z-index: -1;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0); }

/* Checked styles */
[type="radio"]:checked + label:before {
  border: 2px solid transparent;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #26a69a; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #26a69a;
  z-index: 0; }

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02); }

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5); }

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD; }

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left; }

form p:last-child {
  margin-bottom: 0; }

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="checkbox"] {
  /* checkbox aspect */ }

[type="checkbox"] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */ }

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 1px solid #b1b8bb;
  border-radius: 1px;
  margin-top: 2px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }

[type="checkbox"]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0); }

[type="checkbox"]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

[type="checkbox"].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26); }

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent; }

[type="checkbox"].filled-in + label:after {
  border-radius: 2px; }

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  -webkit-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  -o-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1; }

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  -ms-transform: rotate(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #b1b8bb;
  top: 0px;
  z-index: 0; }

[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  -ms-transform: rotate(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0; }

[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD; }

[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent; }

[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD; }

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }

.switch label {
  cursor: pointer; }

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }

.switch label input[type=checkbox]:checked + .lever {
  background-color: #84c7c1; }

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #26a69a;
  left: 24px; }

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px; }

.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: #F1F1F1;
  border-radius: 21px;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  -webkit-transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease;
  transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease;
  -o-transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease; }

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1); }

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08); }

.switch input[type=checkbox][disabled] + .lever {
  cursor: default; }

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #BDBDBD; }

.scale-up {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  -webkit-transform-origin: right 0px;
  -ms-transform-origin: right 0px;
  transform-origin: right 0px; }

.scale-up-left {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  -webkit-transform-origin: left 0px;
  -ms-transform-origin: left 0px;
  transform-origin: left 0px; }

.show > .scale-up {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: right 0px;
  -ms-transform-origin: right 0px;
  transform-origin: right 0px; }

.show > .scale-up-left {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: left 0px;
  -ms-transform-origin: left 0px;
  transform-origin: left 0px; }

/*panels*/
.card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.well, pre {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.page-titles .justify-content-end:last-child .d-flex {
  margin-right: 10px; }

.btn-circle.right-side-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 25px;
  z-index: 10; }

/*Radio button*/
@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px transparent;
    box-shadow: 0px 0px 0px 1px transparent; }
  50% {
    -webkit-box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1); }
  100% {
    -webkit-box-shadow: 0px 0px 0px 15px transparent;
    box-shadow: 0px 0px 0px 15px transparent; } }
@keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px transparent;
    box-shadow: 0px 0px 0px 1px transparent; }
  50% {
    -webkit-box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1); }
  100% {
    -webkit-box-shadow: 0px 0px 0px 15px transparent;
    box-shadow: 0px 0px 0px 15px transparent; } }

/*Floating label*/
/*Bootstrap Select*/
.bootstrap-select.btn-group .dropdown-menu {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.demo-checkbox label, .demo-radio-button label {
  min-width: 200px;
  margin-bottom: 20px; }

.demo-swtich .demo-switch-title, .demo-swtich .switch {
  width: 150px;
  margin-bottom: 10px;
  display: inline-block; }

[type="checkbox"] + label {
  padding-left: 26px;
  height: 25px;
  line-height: 21px;
  font-weight: normal; }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -2px;
  width: 11px;
  height: 19px; }

[type="checkbox"]:checked.chk-col-red + label:before {
  border-right: 2px solid #fb3a3a;
  border-bottom: 2px solid #fb3a3a; }

[type="checkbox"]:checked.chk-col-pink + label:before {
  border-right: 2px solid #E91E63;
  border-bottom: 2px solid #E91E63; }

[type="checkbox"]:checked.chk-col-purple + label:before {
  border-right: 2px solid #7460ee;
  border-bottom: 2px solid #7460ee; }

[type="checkbox"]:checked.chk-col-deep-purple + label:before {
  border-right: 2px solid #673AB7;
  border-bottom: 2px solid #673AB7; }

[type="checkbox"]:checked.chk-col-indigo + label:before {
  border-right: 2px solid #3F51B5;
  border-bottom: 2px solid #3F51B5; }

[type="checkbox"]:checked.chk-col-blue + label:before {
  border-right: 2px solid #02bec9;
  border-bottom: 2px solid #02bec9; }

[type="checkbox"]:checked.chk-col-light-blue + label:before {
  border-right: 2px solid #03A9F4;
  border-bottom: 2px solid #03A9F4; }

[type="checkbox"]:checked.chk-col-cyan + label:before {
  border-right: 2px solid #00BCD4;
  border-bottom: 2px solid #00BCD4; }

[type="checkbox"]:checked.chk-col-teal + label:before {
  border-right: 2px solid #009688;
  border-bottom: 2px solid #009688; }

[type="checkbox"]:checked.chk-col-green + label:before {
  border-right: 2px solid #26c6da;
  border-bottom: 2px solid #26c6da; }

[type="checkbox"]:checked.chk-col-light-green + label:before {
  border-right: 2px solid #8BC34A;
  border-bottom: 2px solid #8BC34A; }

[type="checkbox"]:checked.chk-col-lime + label:before {
  border-right: 2px solid #CDDC39;
  border-bottom: 2px solid #CDDC39; }

[type="checkbox"]:checked.chk-col-yellow + label:before {
  border-right: 2px solid #ffe821;
  border-bottom: 2px solid #ffe821; }

[type="checkbox"]:checked.chk-col-amber + label:before {
  border-right: 2px solid #FFC107;
  border-bottom: 2px solid #FFC107; }

[type="checkbox"]:checked.chk-col-orange + label:before {
  border-right: 2px solid #FF9800;
  border-bottom: 2px solid #FF9800; }

[type="checkbox"]:checked.chk-col-deep-orange + label:before {
  border-right: 2px solid #FF5722;
  border-bottom: 2px solid #FF5722; }

[type="checkbox"]:checked.chk-col-brown + label:before {
  border-right: 2px solid #795548;
  border-bottom: 2px solid #795548; }

[type="checkbox"]:checked.chk-col-grey + label:before {
  border-right: 2px solid #9E9E9E;
  border-bottom: 2px solid #9E9E9E; }

[type="checkbox"]:checked.chk-col-blue-grey + label:before {
  border-right: 2px solid #607D8B;
  border-bottom: 2px solid #607D8B; }

[type="checkbox"]:checked.chk-col-black + label:before {
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000; }

[type="checkbox"]:checked.chk-col-white + label:before {
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff; }

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0; }

[type="checkbox"].filled-in:checked + label:before {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important; }

[type="checkbox"].filled-in:checked.chk-col-red + label:after {
  border: 2px solid #fb3a3a;
  background-color: #fb3a3a; }

[type="checkbox"].filled-in:checked.chk-col-pink + label:after {
  border: 2px solid #E91E63;
  background-color: #E91E63; }

[type="checkbox"].filled-in:checked.chk-col-purple + label:after {
  border: 2px solid #7460ee;
  background-color: #7460ee; }

[type="checkbox"].filled-in:checked.chk-col-deep-purple + label:after {
  border: 2px solid #673AB7;
  background-color: #673AB7; }

[type="checkbox"].filled-in:checked.chk-col-indigo + label:after {
  border: 2px solid #3F51B5;
  background-color: #3F51B5; }

[type="checkbox"].filled-in:checked.chk-col-blue + label:after {
  border: 2px solid #02bec9;
  background-color: #02bec9; }

[type="checkbox"].filled-in:checked.chk-col-light-blue + label:after {
  border: 2px solid #03A9F4;
  background-color: #03A9F4; }

[type="checkbox"].filled-in:checked.chk-col-cyan + label:after {
  border: 2px solid #00BCD4;
  background-color: #00BCD4; }

[type="checkbox"].filled-in:checked.chk-col-teal + label:after {
  border: 2px solid #009688;
  background-color: #009688; }

[type="checkbox"].filled-in:checked.chk-col-green + label:after {
  border: 2px solid #26c6da;
  background-color: #26c6da; }

[type="checkbox"].filled-in:checked.chk-col-light-green + label:after {
  border: 2px solid #8BC34A;
  background-color: #8BC34A; }

[type="checkbox"].filled-in:checked.chk-col-lime + label:after {
  border: 2px solid #CDDC39;
  background-color: #CDDC39; }

[type="checkbox"].filled-in:checked.chk-col-yellow + label:after {
  border: 2px solid #ffe821;
  background-color: #ffe821; }

[type="checkbox"].filled-in:checked.chk-col-amber + label:after {
  border: 2px solid #FFC107;
  background-color: #FFC107; }

[type="checkbox"].filled-in:checked.chk-col-orange + label:after {
  border: 2px solid #FF9800;
  background-color: #FF9800; }

[type="checkbox"].filled-in:checked.chk-col-deep-orange + label:after {
  border: 2px solid #FF5722;
  background-color: #FF5722; }

[type="checkbox"].filled-in:checked.chk-col-brown + label:after {
  border: 2px solid #795548;
  background-color: #795548; }

[type="checkbox"].filled-in:checked.chk-col-grey + label:after {
  border: 2px solid #9E9E9E;
  background-color: #9E9E9E; }

[type="checkbox"].filled-in:checked.chk-col-blue-grey + label:after {
  border: 2px solid #607D8B;
  background-color: #607D8B; }

[type="checkbox"].filled-in:checked.chk-col-black + label:after {
  border: 2px solid #000000;
  background-color: #000000; }

[type="checkbox"].filled-in:checked.chk-col-white + label:after {
  border: 2px solid #ffffff;
  background-color: #ffffff; }

[type="radio"]:not(:checked) + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal; }

[type="radio"]:checked + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal; }

[type="radio"].radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border-color: #fb3a3a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border-color: #7460ee;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border-color: #673AB7;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border-color: #3F51B5;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border-color: #02bec9;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border-color: #03A9F4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border-color: #00BCD4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-teal:checked + label:after {
  background-color: #009688;
  border-color: #009688;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-green:checked + label:after {
  background-color: #26c6da;
  border-color: #26c6da;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border-color: #8BC34A;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border-color: #CDDC39;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border-color: #ffe821;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border-color: #FFC107;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border-color: #FF9800;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border-color: #FF5722;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-brown:checked + label:after {
  background-color: #795548;
  border-color: #795548;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border-color: #9E9E9E;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border-color: #607D8B;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-black:checked + label:after {
  background-color: #000000;
  border-color: #000000;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-white:checked + label:after {
  background-color: #ffffff;
  border-color: #ffffff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-red:checked + label:before {
  border: 2px solid #fb3a3a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-pink:checked + label:before {
  border: 2px solid #E91E63;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border: 2px solid #E91E63;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-purple:checked + label:before {
  border: 2px solid #7460ee;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-purple:checked + label:before {
  border: 2px solid #673AB7;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border: 2px solid #673AB7;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-indigo:checked + label:before {
  border: 2px solid #3F51B5;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border: 2px solid #3F51B5;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue:checked + label:before {
  border: 2px solid #02bec9;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-blue:checked + label:before {
  border: 2px solid #03A9F4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border: 2px solid #03A9F4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-cyan:checked + label:before {
  border: 2px solid #00BCD4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border: 2px solid #00BCD4;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-teal:checked + label:before {
  border: 2px solid #009688;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-teal:checked + label:after {
  background-color: #009688;
  border: 2px solid #009688;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-green:checked + label:before {
  border: 2px solid #26c6da;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-green:checked + label:after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-green:checked + label:before {
  border: 2px solid #8BC34A;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border: 2px solid #8BC34A;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-lime:checked + label:before {
  border: 2px solid #CDDC39;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border: 2px solid #CDDC39;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-yellow:checked + label:before {
  border: 2px solid #ffe821;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-amber:checked + label:before {
  border: 2px solid #FFC107;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border: 2px solid #FFC107;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-orange:checked + label:before {
  border: 2px solid #FF9800;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border: 2px solid #FF9800;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-orange:checked + label:before {
  border: 2px solid #FF5722;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border: 2px solid #FF5722;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-brown:checked + label:before {
  border: 2px solid #795548;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-brown:checked + label:after {
  background-color: #795548;
  border: 2px solid #795548;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-grey:checked + label:before {
  border: 2px solid #9E9E9E;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border: 2px solid #9E9E9E;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue-grey:checked + label:before {
  border: 2px solid #607D8B;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border: 2px solid #607D8B;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-black:checked + label:before {
  border: 2px solid #000000;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-black:checked + label:after {
  background-color: #000000;
  border: 2px solid #000000;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-white:checked + label:before {
  border: 2px solid #ffffff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-white:checked + label:after {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards; }

.switch label {
  font-weight: normal;
  font-size: 13px; }
  .switch label .lever {
    margin: 0 14px; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-red:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-red {
    background-color: rgba(251, 58, 58, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-red:after {
      background-color: #fb3a3a; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-pink:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-pink {
    background-color: rgba(233, 30, 99, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-pink:after {
      background-color: #E91E63; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-purple:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-purple {
    background-color: rgba(116, 96, 238, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-purple:after {
      background-color: #7460ee; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-purple:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple {
    background-color: rgba(103, 58, 183, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple:after {
      background-color: #673AB7; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-indigo:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-indigo {
    background-color: rgba(63, 81, 181, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-indigo:after {
      background-color: #3F51B5; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-blue {
    background-color: rgba(2, 190, 201, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-blue:after {
      background-color: #02bec9; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-blue:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-light-blue {
    background-color: rgba(3, 169, 244, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-light-blue:after {
      background-color: #03A9F4; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-cyan:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-cyan {
    background-color: rgba(0, 188, 212, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-cyan:after {
      background-color: #00BCD4; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-teal:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-teal {
    background-color: rgba(0, 150, 136, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-teal:after {
      background-color: #009688; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-green:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-green {
    background-color: rgba(38, 198, 218, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-green:after {
      background-color: #26c6da; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-green:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-light-green {
    background-color: rgba(139, 195, 74, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-light-green:after {
      background-color: #8BC34A; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-lime:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-lime {
    background-color: rgba(205, 220, 57, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-lime:after {
      background-color: #CDDC39; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-yellow:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-yellow {
    background-color: rgba(255, 232, 33, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-yellow:after {
      background-color: #ffe821; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-amber:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-amber {
    background-color: rgba(255, 193, 7, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-amber:after {
      background-color: #FFC107; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-orange:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-orange {
    background-color: rgba(255, 152, 0, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-orange:after {
      background-color: #FF9800; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-orange:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange {
    background-color: rgba(255, 87, 34, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange:after {
      background-color: #FF5722; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-brown:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-brown {
    background-color: rgba(121, 85, 72, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-brown:after {
      background-color: #795548; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-grey:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-grey {
    background-color: rgba(158, 158, 158, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-grey:after {
      background-color: #9E9E9E; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue-grey:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey {
    background-color: rgba(96, 125, 139, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey:after {
      background-color: #607D8B; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-black:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-black {
    background-color: rgba(0, 0, 0, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-black:after {
      background-color: #000000; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-white:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-white {
    background-color: rgba(255, 255, 255, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-white:after {
      background-color: #ffffff; }

/*Form Validation*/
.help-block ul {
  padding: 0px;
  margin: 0px; }
  .help-block ul li {
    list-style: none; }

.error .form-control {
  border-color: #ef5350; }

.validate .form-control {
  border-color: #06d79c; }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Table Cell
*******************/
.table-box {
  display: table;
  width: 100%; }

.table.no-border tbody td {
  border: 0px; }

.cell {
  display: table-cell;
  vertical-align: middle; }

.table td,
.table th {
  border-color: #f3f1f1; }

.table thead th,
.table th {
  font-weight: 500; }

.table-hover tbody tr:hover {
  background: #e9edf2; }

.nowrap {
  white-space: nowrap !important; }

.lite-padding td {
  padding: 5px; }

.v-middle td,
.v-middle th {
  vertical-align: middle; }

/*******************
Table-Layout
******************/
.table thead th,
.table th {
  border: 0px; }

.color-table.primary-table thead th {
  background-color: #745af2;
  color: #ffffff; }

.table-striped tbody tr:nth-of-type(odd) {
  background: #e9edf2; }

.color-table.success-table thead th {
  background-color: #06d79c;
  color: #ffffff; }

.color-table.info-table thead th {
  background-color: #398bf7;
  color: #ffffff; }

.color-table.warning-table thead th {
  background-color: #ffb22b;
  color: #ffffff; }

.color-table.danger-table thead th {
  background-color: #ef5350;
  color: #ffffff; }

.color-table.inverse-table thead th {
  background-color: #2f3d4a;
  color: #ffffff; }

.color-table.dark-table thead th {
  background-color: #263238;
  color: #ffffff; }

.color-table.red-table thead th {
  background-color: #fb3a3a;
  color: #ffffff; }

.color-table.purple-table thead th {
  background-color: #7460ee;
  color: #ffffff; }

.color-table.muted-table thead th {
  background-color: #99abb4;
  color: #ffffff; }

.color-bordered-table.primary-bordered-table {
  border: 2px solid #745af2; }
  .color-bordered-table.primary-bordered-table thead th {
    background-color: #745af2;
    color: #ffffff; }

.color-bordered-table.success-bordered-table {
  border: 2px solid #06d79c; }
  .color-bordered-table.success-bordered-table thead th {
    background-color: #06d79c;
    color: #ffffff; }

.color-bordered-table.info-bordered-table {
  border: 2px solid #398bf7; }
  .color-bordered-table.info-bordered-table thead th {
    background-color: #398bf7;
    color: #ffffff; }

.color-bordered-table.warning-bordered-table {
  border: 2px solid #ffb22b; }
  .color-bordered-table.warning-bordered-table thead th {
    background-color: #ffb22b;
    color: #ffffff; }

.color-bordered-table.danger-bordered-table {
  border: 2px solid #ef5350; }
  .color-bordered-table.danger-bordered-table thead th {
    background-color: #ef5350;
    color: #ffffff; }

.color-bordered-table.inverse-bordered-table {
  border: 2px solid #2f3d4a; }
  .color-bordered-table.inverse-bordered-table thead th {
    background-color: #2f3d4a;
    color: #ffffff; }

.color-bordered-table.dark-bordered-table {
  border: 2px solid #263238; }
  .color-bordered-table.dark-bordered-table thead th {
    background-color: #263238;
    color: #ffffff; }

.color-bordered-table.red-bordered-table {
  border: 2px solid #fb3a3a; }
  .color-bordered-table.red-bordered-table thead th {
    background-color: #fb3a3a;
    color: #ffffff; }

.color-bordered-table.purple-bordered-table {
  border: 2px solid #7460ee; }
  .color-bordered-table.purple-bordered-table thead th {
    background-color: #7460ee;
    color: #ffffff; }

.color-bordered-table.muted-bordered-table {
  border: 2px solid #99abb4; }
  .color-bordered-table.muted-bordered-table thead th {
    background-color: #99abb4;
    color: #ffffff; }

.full-color-table.full-primary-table {
  background-color: #f1effd; }
  .full-color-table.full-primary-table thead th {
    background-color: #745af2;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-primary-table tbody td {
    border: 0; }
  .full-color-table.full-primary-table tr:hover {
    background-color: #745af2;
    color: #ffffff; }

.full-color-table.full-success-table {
  background-color: #e8fdeb; }
  .full-color-table.full-success-table thead th {
    background-color: #06d79c;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-success-table tbody td {
    border: 0; }
  .full-color-table.full-success-table tr:hover {
    background-color: #06d79c;
    color: #ffffff; }

.full-color-table.full-info-table {
  background-color: #cfecfe; }
  .full-color-table.full-info-table thead th {
    background-color: #398bf7;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-info-table tbody td {
    border: 0; }
  .full-color-table.full-info-table tr:hover {
    background-color: #398bf7;
    color: #ffffff; }

.full-color-table.full-warning-table {
  background-color: #fff8ec; }
  .full-color-table.full-warning-table thead th {
    background-color: #ffb22b;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-warning-table tbody td {
    border: 0; }
  .full-color-table.full-warning-table tr:hover {
    background-color: #ffb22b;
    color: #ffffff; }

.full-color-table.full-danger-table {
  background-color: #f9e7eb; }
  .full-color-table.full-danger-table thead th {
    background-color: #ef5350;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-danger-table tbody td {
    border: 0; }
  .full-color-table.full-danger-table tr:hover {
    background-color: #ef5350;
    color: #ffffff; }

.full-color-table.full-inverse-table {
  background-color: #f6f6f6; }
  .full-color-table.full-inverse-table thead th {
    background-color: #2f3d4a;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-inverse-table tbody td {
    border: 0; }
  .full-color-table.full-inverse-table tr:hover {
    background-color: #2f3d4a;
    color: #ffffff; }

.full-color-table.full-dark-table {
  background-color: rgba(43, 43, 43, 0.8); }
  .full-color-table.full-dark-table thead th {
    background-color: #263238;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-dark-table tbody td {
    border: 0;
    color: #ffffff; }
  .full-color-table.full-dark-table tr:hover {
    background-color: #263238;
    color: #ffffff; }

.full-color-table.full-red-table {
  background-color: #f9e7eb; }
  .full-color-table.full-red-table thead th {
    background-color: #fb3a3a;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-red-table tbody td {
    border: 0; }
  .full-color-table.full-red-table tr:hover {
    background-color: #fb3a3a;
    color: #ffffff; }

.full-color-table.full-purple-table {
  background-color: #f1effd; }
  .full-color-table.full-purple-table thead th {
    background-color: #7460ee;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-purple-table tbody td {
    border: 0; }
  .full-color-table.full-purple-table tr:hover {
    background-color: #7460ee;
    color: #ffffff; }

.full-color-table.full-muted-table {
  background-color: rgba(152, 166, 173, 0.2); }
  .full-color-table.full-muted-table thead th {
    background-color: #99abb4;
    border: 0;
    color: #ffffff; }
  .full-color-table.full-muted-table tbody td {
    border: 0; }
  .full-color-table.full-muted-table tr:hover {
    background-color: #99abb4;
    color: #ffffff; }

/*******************
Table-Data Table
******************/
.dataTables_wrapper {
  padding-top: 10px; }

.dt-buttons {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 15px; }
  .dt-buttons .dt-button {
    padding: 5px 15px;
    border-radius: 4px;
    background: #398bf7;
    color: #ffffff;
    margin-right: 3px; }
    .dt-buttons .dt-button:hover {
      background: #2f3d4a; }

.dataTables_info,
.dataTables_length {
  display: inline-block; }

.dataTables_length {
  margin-top: 10px; }
  .dataTables_length select {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
    background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
    background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
    background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    padding-bottom: 5px; }
    .dataTables_length select:focus {
      outline: none;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
      background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
      background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
      background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
      background-size: 100% 2px, 100% 1px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s; }

.dataTables_filter {
  float: right;
  margin-top: 10px; }
  .dataTables_filter input {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
    background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
    background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
    background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    margin-left: 10px; }
    .dataTables_filter input:focus {
      outline: none;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
      background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
      background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
      background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
      background-size: 100% 2px, 100% 1px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent; }

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  margin-left: 10px;
  font-family: fontawesome;
  cursor: pointer; }

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  margin-left: 10px;
  font-family: fontawesome;
  cursor: pointer; }

table.dataTable thead .sorting:after {
  content: "\f0dc";
  margin-left: 10px;
  font-family: fontawesome !important;
  cursor: pointer;
  color: rgba(50, 50, 50, 0.5); }

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em; }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  *cursor: hand;
  color: #67757c;
  border: 1px solid #ddd; }

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #ffffff !important;
  border: 1px solid #398bf7;
  background-color: #398bf7; }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #67757c;
  border: 1px solid #ddd;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white;
  border: 1px solid #398bf7;
  background-color: #398bf7; }

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #67757c; }

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em; }

/*******************
Table- responsive
******************/
.tablesaw-bar .btn-group label {
  color: #67757c !important; }

/*******************
Table- editable table
******************/
.dt-bootstrap {
  display: block; }

.paging_simple_numbers .pagination .paginate_button {
  padding: 0px;
  background: #ffffff; }
  .paging_simple_numbers .pagination .paginate_button:hover {
    background: #ffffff; }
  .paging_simple_numbers .pagination .paginate_button a {
    padding: 3px 10px;
    border: 0px; }
  .paging_simple_numbers .pagination .paginate_button.active a,
  .paging_simple_numbers .pagination .paginate_button:hover a {
    background: #398bf7;
    color: #ffffff; }

.stylish-table tbody tr {
  border-left: 3px solid transparent; }
  .stylish-table tbody tr:hover, .stylish-table tbody tr.active {
    border-color: #398bf7; }

/*******************
Table-Jsgrid table
******************/
.jsgrid-pager-page a,
.jsgrid-pager-current-page,
.jsgrid-pager-nav-button a {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid #ddd; }

.jsgrid-pager-page a:hover,
.jsgrid-pager-nav-button a:hover {
  background-color: #398bf7;
  color: #ffffff; }

.jsgrid-pager-current-page {
  background-color: #398bf7;
  color: #ffffff; }

.jsgrid-pager-page,
.jsgrid-pager-nav-button {
  padding: 0; }

.jsgrid-pager-page.jsgrid-pager-current-page {
  padding: 0.5em 1em !important; }

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/* perfect-scrollbar v0.7.1 */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none; }

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

.ps.ps--active-x > .ps__scrollbar-x-rail,
.ps.ps--active-y > .ps__scrollbar-y-rail {
  display: block;
  background-color: transparent; }

.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9; }

.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: transparent;
  width: 8px; }

.ps > .ps__scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  bottom: 0px;
  /* there must be 'bottom' for ps__scrollbar-x-rail */
  height: 6px; }

.ps > .ps__scrollbar-x-rail > .ps__scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  bottom: 2px;
  /* there must be 'bottom' for ps__scrollbar-x */
  height: 6px; }

.ps > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x,
.ps > .ps__scrollbar-x-rail:active > .ps__scrollbar-x {
  height: 6px; }

.ps > .ps__scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  right: 0;
  /* there must be 'right' for ps__scrollbar-y-rail */
  width: 6px; }

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  right: 2px;
  /* there must be 'right' for ps__scrollbar-y */
  width: 6px; }

.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y {
  width: 6px; }

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.9; }

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x {
  background-color: rgba(0, 0, 0, 0.2);
  height: 6px; }

.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2);
  width: 6px; }

.ps:hover > .ps__scrollbar-x-rail,
.ps:hover > .ps__scrollbar-y-rail {
  opacity: 0.6; }

.ps:hover > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x {
  background-color: rgba(0, 0, 0, 0.2); }

.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2); }

#slimtest3.ps .ps__scrollbar-y-rail {
  left: 3px;
  right: auto; }

#slimtest4.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: #398bf7; }

#slimtest4.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: #398bf7; }

#slimtest4.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  background-color: #398bf7; }

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
/*User mail widgets*/
/*******************/
.topbar .top-navbar .mailbox {
  width: 300px; }
  .topbar .top-navbar .mailbox ul {
    padding: 0px; }
    .topbar .top-navbar .mailbox ul li {
      list-style: none; }

.mailbox ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

.mailbox ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px; }

.mailbox .message-center {
  height: 200px;
  overflow: auto;
  position: relative; }
  .mailbox .message-center a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px; }
    .mailbox .message-center a:hover {
      background: #e9edf2; }
    .mailbox .message-center a div {
      white-space: normal; }
    .mailbox .message-center a .user-img {
      width: 40px;
      position: relative;
      display: inline-block;
      margin: 0 10px 15px 0; }
      .mailbox .message-center a .user-img img {
        width: 100%; }
      .mailbox .message-center a .user-img .profile-status {
        border: 2px solid #ffffff;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        left: 30px;
        position: absolute;
        top: 1px;
        width: 10px; }
      .mailbox .message-center a .user-img .online {
        background: #06d79c; }
      .mailbox .message-center a .user-img .busy {
        background: #ef5350; }
      .mailbox .message-center a .user-img .away {
        background: #ffb22b; }
      .mailbox .message-center a .user-img .offline {
        background: #ffb22b; }
    .mailbox .message-center a .mail-contnet {
      display: inline-block;
      width: 75%;
      vertical-align: middle; }
      .mailbox .message-center a .mail-contnet h5 {
        margin: 5px 0px 0; }
      .mailbox .message-center a .mail-contnet .mail-desc,
      .mailbox .message-center a .mail-contnet .time {
        font-size: 12px;
        display: block;
        margin: 1px 0;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #67757c;
        white-space: nowrap; }

/*******************/
/*Weather*/
/*******************/
.city-weather-days {
  margin: 0px; }
  .city-weather-days li {
    text-align: center;
    padding: 15px 0; }
    .city-weather-days li span {
      display: block;
      padding: 10px 0 0;
      color: #99abb4; }
    .city-weather-days li i {
      display: block;
      font-size: 20px;
      color: #398bf7; }
    .city-weather-days li h3 {
      font-weight: 300;
      margin-top: 5px; }

/*Weather small*/
.weather-small h1 {
  line-height: 30px; }

.weather-small sup {
  font-size: 60%; }

/*******************/
/*Comment widgets*/
/*******************/
.comment-widgets {
  position: relative;
  margin-bottom: 10px; }
  .comment-widgets .comment-row {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px; }
    .comment-widgets .comment-row:last-child {
      border-bottom: 0px; }
    .comment-widgets .comment-row:hover, .comment-widgets .comment-row.active {
      background: rgba(0, 0, 0, 0.02); }

.comment-text {
  padding: 15px 15px 15px 20px;
  width: 80%; }
  .comment-text:hover .comment-footer .action-icons,
  .comment-text.active .comment-footer .action-icons {
    visibility: visible; }
  .comment-text p {
    max-height: 50px;
    width: 100%;
    overflow: hidden; }

.comment-footer .action-icons {
  visibility: hidden; }
  .comment-footer .action-icons a {
    padding-left: 7px;
    vertical-align: middle;
    color: #99abb4; }
    .comment-footer .action-icons a:hover, .comment-footer .action-icons a.active {
      color: #398bf7; }

/*******************/
/*To do widgets*/
/*******************/
.todo-list li {
  border: 0px;
  margin-bottom: 0px;
  padding: 20px 15px 15px 0px; }
  .todo-list li .checkbox {
    width: 100%; }
    .todo-list li .checkbox label {
      font-weight: 400;
      color: #455a64;
      width: 100%; }
  .todo-list li:last-child {
    border-bottom: 0px; }
  .todo-list li .assignedto {
    padding: 0px 0 0 27px;
    margin: 0px; }
    .todo-list li .assignedto li {
      list-style: none;
      padding: 0px;
      display: inline-block;
      border: 0px;
      margin-right: 2px; }
      .todo-list li .assignedto li img {
        width: 30px;
        border-radius: 100%; }
  .todo-list li .item-date {
    padding-left: 25px;
    font-size: 12px;
    display: inline-block; }

.list-task .task-done span {
  text-decoration: line-through; }

/*******************/
/*Chat widget*/
/*******************/
.chat-list {
  margin: 0px;
  padding: 0px; }
  .chat-list li {
    list-style: none;
    margin-top: 30px; }
    .chat-list li .chat-img {
      display: inline-block;
      width: 45px;
      vertical-align: top; }
      .chat-list li .chat-img img {
        width: 45px;
        border-radius: 100%; }
    .chat-list li .chat-content {
      width: calc(100% - 140px);
      display: inline-block;
      padding-left: 15px; }
      .chat-list li .chat-content h5 {
        color: #99abb4; }
      .chat-list li .chat-content .box {
        display: inline-block;
        margin-bottom: 10px;
        color: #263238; }
    .chat-list li .chat-time {
      display: inline-block;
      text-align: right;
      width: 80px;
      font-size: 13px;
      color: #99abb4; }
    .chat-list li.odd .chat-content {
      text-align: right;
      width: calc(100% - 90px); }
    .chat-list li.odd .box {
      clear: both; }
    .chat-list li.odd + .odd {
      margin-top: 0px; }
    .chat-list li.reverse {
      text-align: right; }
      .chat-list li.reverse .chat-time {
        text-align: left; }
      .chat-list li.reverse .chat-content {
        padding-left: 0px;
        padding-right: 15px; }

/*******************/
/*Chat widget*/
/*******************/
.message-box ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

.message-box ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px; }

.message-box .message-widget {
  position: relative; }
  .message-box .message-widget a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px; }
    .message-box .message-widget a:hover {
      background: #e9edf2; }
    .message-box .message-widget a:last-child {
      border-bottom: 0px; }
    .message-box .message-widget a div {
      white-space: normal; }
    .message-box .message-widget a .user-img {
      width: 45px;
      position: relative;
      display: inline-block;
      margin: 0 10px 15px 0; }
      .message-box .message-widget a .user-img img {
        width: 100%; }
      .message-box .message-widget a .user-img .profile-status {
        border: 2px solid #ffffff;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        left: 33px;
        position: absolute;
        top: -1px;
        width: 10px; }
      .message-box .message-widget a .user-img .online {
        background: #06d79c; }
      .message-box .message-widget a .user-img .busy {
        background: #ef5350; }
      .message-box .message-widget a .user-img .away {
        background: #ffb22b; }
      .message-box .message-widget a .user-img .offline {
        background: #ffb22b; }
    .message-box .message-widget a .mail-contnet {
      display: inline-block;
      width: 70%;
      vertical-align: middle; }
      .message-box .message-widget a .mail-contnet h5 {
        margin: 5px 0px 0; }
      .message-box .message-widget a .mail-contnet .mail-desc,
      .message-box .message-widget a .mail-contnet .time {
        font-size: 12px;
        display: block;
        margin: 1px 0;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #67757c;
        white-space: nowrap; }

/*******************/
/*Calendar widget*/
/*******************/
.calendar {
  float: left;
  margin-bottom: 0px; }

.fc-view {
  margin-top: 30px; }

.none-border .modal-footer {
  border-top: none; }

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px; }
  .fc-toolbar h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase; }

.fc-day {
  background: #ffffff; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0; }

.fc-widget-header {
  border: 0px !important; }

.fc-widget-content {
  border-color: rgba(120, 130, 140, 0.13) !important; }

.fc th.fc-widget-header {
  color: #67757c;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  padding: 7px 0px;
  text-transform: uppercase; }

.fc th.fc-sun,
.fc th.fc-tue,
.fc th.fc-thu,
.fc th.fc-sat {
  background: #f2f7f8; }

.fc th.fc-mon,
.fc th.fc-wed,
.fc th.fc-fri {
  background: #f2f7f8; }

.fc-view {
  margin-top: 0px; }

.fc-toolbar {
  margin: 0px;
  padding: 24px 0px; }

.fc-button {
  background: #ffffff;
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #67757c;
  text-transform: capitalize; }
  .fc-button:hover {
    background: #e9edf2;
    opacity: 0.8; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px; }

.fc-state-hover {
  background: #F5F5F5; }

.fc-unthemed .fc-today {
  border: 1px solid #ef5350;
  background: #e9edf2 !important; }

.fc-state-highlight {
  background: #f0f0f0; }

.fc-cell-overlay {
  background: #f0f0f0; }

.fc-unthemed .fc-today {
  background: #ffffff; }

.fc-event {
  border-radius: 0px;
  border: none;
  cursor: move;
  color: #ffffff !important;
  font-size: 13px;
  margin: 1px -1px 0 -1px;
  padding: 5px 5px;
  text-align: center;
  background: #398bf7; }

.calendar-event {
  cursor: move;
  margin: 10px 5px 0 0;
  padding: 6px 10px;
  display: inline-block;
  color: #ffffff;
  min-width: 140px;
  text-align: center;
  background: #398bf7; }
  .calendar-event a {
    float: right;
    opacity: 0.6;
    font-size: 10px;
    margin: 4px 0 0 10px;
    color: #ffffff; }

.fc-basic-view td.fc-week-number span {
  padding-right: 5px; }

.fc-basic-view .fc-day-number {
  padding: 10px 15px;
  display: inline-block; }

/*******************/
/*Steam line widget*/
/*******************/
.steamline {
  position: relative;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  margin-left: 20px; }
  .steamline .sl-left {
    float: left;
    margin-left: -20px;
    z-index: 1;
    width: 40px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    border-radius: 100%;
    color: #ffffff;
    background: #263238;
    margin-right: 15px; }
    .steamline .sl-left img {
      max-width: 40px; }

.steamline .sl-right {
  padding-left: 50px; }
  .steamline .sl-right .desc,
  .steamline .sl-right .inline-photos {
    margin-bottom: 30px; }

.steamline .sl-item {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin: 20px 0; }

.sl-date {
  font-size: 10px;
  color: #99abb4; }

.time-item {
  border-color: rgba(120, 130, 140, 0.13);
  padding-bottom: 1px;
  position: relative; }
  .time-item:before {
    content: " ";
    display: table; }
  .time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px; }

.time-item-item:after {
  content: " ";
  display: table; }

.item-info {
  margin-bottom: 15px;
  margin-left: 15px; }
  .item-info p {
    margin-bottom: 10px !important; }

/*******************/
/*Feed widget*/
/*******************/
.feeds {
  margin: 0px;
  padding: 0px; }
  .feeds li {
    list-style: none;
    padding: 10px;
    display: block; }
    .feeds li:hover {
      background: #ebf3f5; }
    .feeds li > div {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      border-radius: 100%; }
      .feeds li > div i {
        line-height: 40px; }
    .feeds li span {
      float: right;
      width: auto;
      font-size: 12px; }

/*******************/
/*Vertical carousel*/
/*******************/
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.vert .carousel-item-next,
.vert .active.carousel-item-right {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100% 0); }

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

/*******************/
/*social-widgets*/
/*******************/
.social-widget .soc-header {
  padding: 15px;
  text-align: center;
  font-size: 36px;
  color: #fff; }
  .social-widget .soc-header.box-facebook {
    background: #3b5998; }
  .social-widget .soc-header.box-twitter {
    background: #00aced; }
  .social-widget .soc-header.box-google {
    background: #f86c6b; }
  .social-widget .soc-header.box-linkedin {
    background: #4875b4; }

.social-widget .soc-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center; }
  .social-widget .soc-content div {
    padding: 10px; }
    .social-widget .soc-content div h3 {
      margin-bottom: 0px; }

/*******************/
/*social-profile-first*/
/*******************/
.social-profile-first {
  text-align: center;
  padding-top: 22%;
  margin-bottom: 96px; }
  .social-profile-first.bg-over {
    background: rgba(56, 83, 161, 0.7); }
  .social-profile-first .middle {
    vertical-align: middle; }

/*******************/
/*profile timeline widget*/
/*******************/
.profiletimeline {
  position: relative;
  padding-left: 40px;
  margin-right: 10px;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  margin-left: 30px; }
  .profiletimeline .sl-left {
    float: left;
    margin-left: -60px;
    z-index: 1;
    margin-right: 15px; }
    .profiletimeline .sl-left img {
      max-width: 40px; }

.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px; }

.profiletimeline .sl-date {
  font-size: 12px;
  color: #99abb4; }

.profiletimeline .time-item {
  border-color: rgba(120, 130, 140, 0.13);
  padding-bottom: 1px;
  position: relative; }
  .profiletimeline .time-item:before {
    content: " ";
    display: table; }
  .profiletimeline .time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px; }

.profiletimeline .time-item-item:after {
  content: " ";
  display: table; }

.profiletimeline .item-info {
  margin-bottom: 15px;
  margin-left: 15px; }
  .profiletimeline .item-info p {
    margin-bottom: 10px !important; }

/*little-profile*/
.little-profile .pro-img {
  margin-bottom: 20px; }
  .little-profile .pro-img img {
    width: 128px;
    height: 128px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%; }

.little-profile .soc-pro a {
  color: #99abb4; }
  .little-profile .soc-pro a:hover {
    color: #263238; }

/*******************
/*States row*/
/*******************/
.stats-row {
  margin-bottom: 20px; }
  .stats-row .stat-item {
    display: inline-block;
    padding-right: 15px; }
    .stats-row .stat-item + .stat-item {
      padding-left: 15px;
      border-left: 1px solid rgba(120, 130, 140, 0.13); }

/*******************/
/*Guage chart*/
/*******************/
.gaugejs-box {
  position: relative;
  margin: 0 auto; }

.gaugejs-box canvas.gaugejs {
  width: 100% !important;
  height: auto !important; }

/*******************/
/*country-state*/
/*******************/
.country-state {
  list-style: none;
  margin: 0;
  padding: 0 0 0 10px; }
  .country-state li {
    margin-top: 30px;
    margin-bottom: 10px; }
  .country-state h2 {
    margin-bottom: 0px;
    font-weight: 400; }

/*contact widgets*/
.contact-box {
  position: relative; }
  .contact-box .add-ct-btn {
    position: absolute;
    right: 4px;
    top: -46px; }
  .contact-box .contact-widget > a {
    padding: 15px 10px; }
    .contact-box .contact-widget > a .user-img {
      margin-bottom: 0px !important; }

/*Blog widgets*/
.blog-widget {
  margin-top: 30px; }
  .blog-widget .blog-image img {
    border-radius: 4px;
    margin-top: -45px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

/*******************/
/*Msg box*/
/*******************/
.msg-item {
  margin-bottom: 20px; }

.msg-body {
  background: #ffffff;
  padding: 15px;
  font-size: 14px;
  position: relative; }
  .msg-body .dwn-aro {
    width: 0;
    position: absolute;
    bottom: -8px;
    height: 0;
    left: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff; }

.m-pic img {
  width: 40px;
  border-radius: 100%; }

/*******************/
/*Activity widgets*/
/*******************/
.activity-box .date-devider {
  border-top: 2px solid rgba(120, 130, 140, 0.13);
  position: relative; }
  .activity-box .date-devider span {
    background: #e9edf2;
    padding: 5px 15px;
    border-radius: 60px;
    font-size: 14px;
    top: -15px;
    position: relative;
    margin-left: 20px; }

.activity-box .activity-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px; }
  .activity-box .activity-item .image-list > a {
    margin-left: -15px;
    position: relative;
    vertical-align: middle; }
    .activity-box .activity-item .image-list > a:first-child, .activity-box .activity-item .image-list > a:last-child {
      margin-left: 0px; }
    .activity-box .activity-item .image-list > a:hover {
      z-index: 10; }
      .activity-box .activity-item .image-list > a:hover img {
        -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2); }

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
@media (min-width: 1600px) {
  .col-xlg-1, .col-xlg-10, .col-xlg-11, .col-xlg-12, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9 {
    float: left; }
  .col-xlg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-xlg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66666667%;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xlg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333333%;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xlg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xlg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66666667%;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xlg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333333%;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xlg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xlg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66666667%;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xlg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333333%;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xlg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xlg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66666667%;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xlg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333333%;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xlg-pull-12 {
    right: 100%; }
  .col-xlg-pull-11 {
    right: 91.66666667%; }
  .col-xlg-pull-10 {
    right: 83.33333333%; }
  .col-xlg-pull-9 {
    right: 75%; }
  .col-xlg-pull-8 {
    right: 66.66666667%; }
  .col-xlg-pull-7 {
    right: 58.33333333%; }
  .col-xlg-pull-6 {
    right: 50%; }
  .col-xlg-pull-5 {
    right: 41.66666667%; }
  .col-xlg-pull-4 {
    right: 33.33333333%; }
  .col-xlg-pull-3 {
    right: 25%; }
  .col-xlg-pull-2 {
    right: 16.66666667%; }
  .col-xlg-pull-1 {
    right: 8.33333333%; }
  .col-xlg-pull-0 {
    right: auto; }
  .col-xlg-push-12 {
    left: 100%; }
  .col-xlg-push-11 {
    left: 91.66666667%; }
  .col-xlg-push-10 {
    left: 83.33333333%; }
  .col-xlg-push-9 {
    left: 75%; }
  .col-xlg-push-8 {
    left: 66.66666667%; }
  .col-xlg-push-7 {
    left: 58.33333333%; }
  .col-xlg-push-6 {
    left: 50%; }
  .col-xlg-push-5 {
    left: 41.66666667%; }
  .col-xlg-push-4 {
    left: 33.33333333%; }
  .col-xlg-push-3 {
    left: 25%; }
  .col-xlg-push-2 {
    left: 16.66666667%; }
  .col-xlg-push-1 {
    left: 8.33333333%; }
  .col-xlg-push-0 {
    left: auto; }
  .offset-xlg-12 {
    margin-left: 100%; }
  .offset-xlg-11 {
    margin-left: 91.66666667%; }
  .offset-xlg-10 {
    margin-left: 83.33333333%; }
  .offset-xlg-9 {
    margin-left: 75%; }
  .offset-xlg-8 {
    margin-left: 66.66666667%; }
  .offset-xlg-7 {
    margin-left: 58.33333333%; }
  .offset-xlg-6 {
    margin-left: 50%; }
  .offset-xlg-5 {
    margin-left: 41.66666667%; }
  .offset-xlg-4 {
    margin-left: 33.33333333%; }
  .offset-xlg-3 {
    margin-left: 25%; }
  .offset-xlg-2 {
    margin-left: 16.66666667%; }
  .offset-xlg-1 {
    margin-left: 8.33333333%; }
  .offset-xlg-0 {
    margin-left: 0; } }

.col-xlg-1, .col-xlg-10, .col-xlg-11, .col-xlg-12, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.invisible {
  visibility: hidden !important; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.card-inverse .card-blockquote, .card-inverse .card-footer, .card-inverse .card-header, .card-inverse .card-title {
  color: #ffffff; }

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */
@media (min-width: 1650px) {
  .widget-app-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3; }
  .campaign {
    height: 365px !important; }
  .website-visitor {
    height: 445px; }
  .auto-height {
    max-height: 315px; } }

@media (max-width: 1600px) {
  .website-visitor {
    height: 333px; }
  .auto-height {
    min-height: 240px; } }

/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */
@media (max-width: 1370px) {
  .widget-app-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; }
  .website-visitor {
    height: 353px; }
  .auto-height {
    min-height: 186px; } }

/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (min-width: 1024px) {
  .page-wrapper {
    margin-left: 240px; } }

@media (max-width: 1023px) {
  .page-wrapper {
    margin-left: 70px;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in; }
  .widget-app-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; }
  .inbox-center a {
    width: 200px; }
  .hdr-nav-bar .navbar .navbar-nav > li a {
    padding: 12px 15px;
    border-bottom: 0px solid transparent;
    border-left: 2px solid transparent; }
  .hdr-nav-bar .navbar .navbar-nav > li:hover a {
    padding: 12px 20px; }
  .hdr-nav-bar .call-to-act .custom-select {
    margin-bottom: 10px; }
  .d-flex {
    display: block !important; }
    .d-flex.no-block {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important; } }

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
@media (min-width: 768px) {
  .navbar-header {
    width: 240px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .navbar-header .navbar-brand {
      padding-top: 0px; }
  .card-group .card:first-child,
  .card-group .card:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.03); }
  .material-icon-list-demo .icons div {
    width: 33%;
    padding: 15px;
    display: inline-block;
    line-height: 40px; }
  .mini-sidebar .page-wrapper {
    margin-left: 70px; }
  .flex-wrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap !important; } }

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@media (max-width: 767px) {
  .container-fluid {
    padding: 25px 15px 25px 15px; }
  /*Header*/
  .topbar {
    position: fixed;
    width: 100%; }
    .topbar .top-navbar {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-align-items: center; }
      .topbar .top-navbar .navbar-collapse {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%; }
      .topbar .top-navbar .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .topbar .top-navbar .navbar-nav > .nav-item.show {
          position: static; }
          .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
            width: 100%;
            margin-top: 0px; }
        .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
          padding-left: .50rem;
          padding-right: .50rem; }
        .topbar .top-navbar .navbar-nav .dropdown-menu {
          position: absolute; }
  .mega-dropdown .dropdown-menu {
    height: 480px;
    overflow: auto; }
  /*Sidebar and wrapper*/
  .mini-sidebar .page-wrapper {
    margin-left: 0px;
    padding-top: 70px; }
  .comment-text .comment-footer .action-icons {
    display: block;
    padding: 10px 0; }
  .vtabs .tabs-vertical {
    width: auto; }
  /*Footer*/
  .material-icon-list-demo .icons div {
    width: 100%; }
  .error-page .footer {
    position: fixed;
    bottom: 0px;
    z-index: 10; }
  .error-box {
    position: relative;
    padding-bottom: 60px; }
  .error-body {
    padding-top: 10%; }
    .error-body h1 {
      font-size: 100px;
      font-weight: 600;
      line-height: 100px; }
  .login-register {
    position: relative;
    overflow: hidden; }
  .login-box {
    width: 90%; }
  .login-sidebar {
    padding: 10% 0; }
    .login-sidebar .login-box {
      position: relative; }
  /*This is for chat page*/
  .chat-main-box .chat-left-aside {
    left: -250px;
    position: absolute;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
    background: #ffffff; }
    .chat-main-box .chat-left-aside.open-pnl {
      left: 0px; }
    .chat-main-box .chat-left-aside .open-panel {
      display: block; }
  .chat-main-box .chat-right-aside {
    width: 100%; }
  /*Timeline*/
  ul.timeline:before {
    left: 40px; }
  ul.timeline > li > .timeline-panel {
    width: "calc(100% - 90px)";
    width: "calc(100% - 90px)";
    width: "calc(100% - 90px)"; }
  ul.timeline > li > .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0; }
  ul.timeline > li > .timeline-panel {
    float: right; }
  ul.timeline > li > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0; }
  ul.timeline > li > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0; }
  /*Contact app page*/
  .left-aside {
    width: 100%;
    position: relative;
    border: 0px; }
  .right-aside {
    margin-left: 0px; }
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important; }
  .chat-list li .chat-content {
    width: calc(100% - 80px); }
  /*Calendar*/
  .fc-toolbar .fc-left, .fc-toolbar .fc-right, .fc-toolbar .fc-center {
    float: none;
    display: block;
    text-align: center;
    margin-top: 10px;
    overflow: hidden; } }
